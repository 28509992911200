import CompanyModel from "../models/CompanyModel";
import HttpService from "../../common/services/HttpService";

class CompanyService { 
    static instance = new CompanyService();

    constructor() {
        this.companyMap = {};
        this.usersMap = {};
        this.apisMap = {};
        this.companies = null; // []
    }

    async getCompanyAsync(companyId, headers = null) {
        const path = "/api/company/" + companyId;
        const me = this;
        
        return await HttpService.instance.getAsync(path, true, headers).then((response) => {
            const company = new CompanyModel(response.data);
            me.companyMap[company.id] = company;
            return company;
        });
    }
    
    async getCompanyByDomainAsync(domain = null) {
        if (!domain || domain.length === 0) domain = window.location.host;
        if (!domain || typeof domain !== "string") throw new Error("Invalid or missing domain: " + domain);

        domain = domain.toLowerCase();

        const path = "/api/company-domain?domain=" + domain;
        const me = this;
        
        return await HttpService.instance.getAsync(path, true).then((response) => {
            const company = new CompanyModel(response.data);
            if (!!company?.id && company.id.length > 0) {
                me.companyMap[company.id] = company;
                me.companyMap[domain] = company;
                return company;
            }

            return null;
        });
    }
    
}

export default CompanyService;
