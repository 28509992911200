import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FinanceMasterPage from '../finance/FinanceMasterPage';

const MediaScreen = ({ title }) => {
    useEffect(() => { 
        
    }, []);

    return (<FinanceMasterPage>
        <div><h1>Media: {title || "No Title"}</h1><Link to={"/1440037f-fb6c-4ddc-bdd7-12e2094bb792"}>Invoice</Link></div>
    </FinanceMasterPage>);
}

export default MediaScreen;
