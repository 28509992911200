import React, { useEffect } from 'react';
import NumberDisplay from "@jadecharles/pi-react-packages/dist/common/ui/formatting/NumberDisplay";
import HttpService from '../../common/services/HttpService';

const AttachmentsPanel = (props) => {
    const { title, documents, empty, documentType, collectionId } = props;

    useEffect(() => { 
        console.log("AttachmentsPanel");
    }, []);

    const titleElement = typeof title === "string" && !!title ? (<div className="attachments-panel-title">{title}</div>) : null;

    if (!Array.isArray(documents)) {
        console.error("No documents passed.");
        return null;
    }

    const emptyElement = (typeof empty === "string") ?
        (<span>{empty}</span>) :
        (empty || null);

    
    const getDocSize = (fileSize) => { 
        if (typeof fileSize !== "number" || fileSize < 0)
            fileSize = 0;
        
        let prefix = "bytes";

        if (fileSize >= 1073741824) {
            fileSize = (fileSize / 1073741824);
            prefix = "GB";
        } else if (fileSize >= 1048576) {
            fileSize = (fileSize / 1048576);
            prefix = "MB";
        } else if (fileSize >= 1024) {
            fileSize = (fileSize / 1024);
            prefix = "KB";
        }

        return (<NumberDisplay suffix={" " + prefix} value={fileSize} decimalPlaces={1} />);
    }

    const downloadAttachmentAsync = async (apiPath, fileName = null) => { 
        console.log("Downloading: " + (fileName || apiPath));

        await HttpService.instance.getBlobAsync(apiPath, { fileName: fileName, download: true }).then((model) => {
            console.log(model.fileName);
            console.warn(model.headers);

        }).catch((ex) => { 
            //
            console.error("Failed to download blob.");
            throw ex;
        });
    };

    const getDocUrl = (document) => {
        //collectionId
        if (typeof collectionId !== "string" || collectionId.length < 30)
            return document.uri;
        
        const dt = typeof documentType === "string" ? documentType : "invoice";
        return "/api/" + dt + "/" + collectionId + "/document/" + document.id + "/download";
    };
    
    const body = documents.length > 0 ? documents.map((document, index) => {
        const docName = document.name;
        const docUrl = getDocUrl(document);
        const docSize = document.fileSize;
        const docSizeDisplay = getDocSize(docSize);

        if (docUrl.startsWith("/api/")) { 
            const fileName = document.name.substr(document.name.length - 4) === document.fileName.substr(document.fileName.length - 4) ?
                document.name :
                document.fileName;

            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return (<a key={index} onClick={() => downloadAttachmentAsync(docUrl, fileName) } rel="noreferrer">{docName} ({docSizeDisplay})</a>);
        }

        return (<a key={index} href={docUrl} target="_blank" rel="noreferrer">{docName} ({docSizeDisplay})</a>);

    }) : null;

    return (<div className="attachments-panel">
        {titleElement}
        {body || emptyElement}
    </div>);
}

export default AttachmentsPanel;
