import HttpService from "../../common/services/HttpService";
import PaymentModel from "../models/PaymentModel";
import InvoiceModel from "../models/InvoiceModel";

class InvoiceService { 
    static instance = new InvoiceService();

    constructor() {
        this.paymentsMap = {};
        this.paymentMap = {};
        this.payments = null;
    }

    /**
     * @param {string} documentId
     * @param {string} invoiceId
     * 
     */
    getPdfDownloadUrl = (documentId, invoiceId) => {
        if (typeof documentId !== "string" || (documentId.length < 30 && !documentId.startsWith("000-"))) throw new Error("Invalid documentId: '" + documentId + "'");
        if (typeof invoiceId !== "string" || invoiceId.length < 30) throw new Error("Invalid invoiceId: '" + invoiceId + "'");

        //collectionId
        if (documentId === "000-001") return "/api/invoice/" + invoiceId + "/invoice-pdf";
        if (documentId === "000-002") return "/api/invoice/" + invoiceId + "/receipt-pdf";

        return "/api/invoice/" + invoiceId + "/document/" + documentId + "/download";
    };

    async downloadInvoicefAsync(invoiceId) { 
        //
    }

    async downloadReceiptPdfAsync(invoiceId) { 
        //
    }

    async downloadPdfAsync(documentId, invoiceId, doDownload = true) {
        const path = this.getPdfDownloadUrl(documentId, invoiceId);
        console.log("Downloaded Blob: " + path);

        const blobModel = await HttpService.instance.getBlobAsync(path);

        if (doDownload) { 
            HttpService.instance.downloadBlob(blobModel);
        }

        return blobModel;
     };
    
    async getInvoiceAsync(invoiceId) {
        const path = '/api/invoice/' + invoiceId;
        console.log("Getting Invoice: " + path);

        return await HttpService.instance.getAsync(path, true).then((response) => {
            if (!response?.data?.id) { 
                console.warn(JSON.stringify(response?.data, null, 4));
                return null;
            }

            return InvoiceModel.create(response.data);
        });
    };

    async capturePaymentAsync(invoiceId, paymentJson) {
        const path = "/api/invoice/" + invoiceId + "/credit-card";
        const me = this;

        return await HttpService.instance.postAsync(path, paymentJson).then((response) => {
            const p = new PaymentModel(response.data);
            if (Array.isArray(me.payments)) me.payments.push(p);
            
            return p;
        });
    }

    
}

export default InvoiceService;
