import React, { useEffect } from 'react';
import './LoadingArea.css';

const LoadingArea = (props) => {
    const { count, style, isSpinner, className, id, width, height, color, children, fullRow, isBlock, target } = props;

    useEffect(() => {
        //
    }, []);

    const st = typeof style === "object" ? style : {};
    const elementId = typeof id === "string" ? id : "";
    const elementClass = typeof className === "string" ? " " + className : "";
    
    if (typeof width === "number" || typeof width === "string")
        st.width = width;
    
    if (typeof height === "number" || typeof height === "string")
        st.height = height;
    
    if (typeof color === "string" && !!color)
        st.backgroundColor = color;

    if (fullRow === false || isBlock === false) st.display = "inline-block";
    
    const targetElement = typeof target !== "undefined" ? target : children;

    if (typeof target === "object") {
        // console.warn("ElementId: " + elementId);
        // console.warn(JSON.stringify(target, null, 4));
    }

    if (!!targetElement) {
        //if (isVisible !== true) return children;
        // console.log("LOADING Id: " + elementId + "");
        // console.log("TARGET Type: " + (typeof target) + "");
        return children;
    }

    if (isSpinner === true) return (<span className="spinning-loader"><span className={"spinning-area" + elementClass}><span className="spinner">&nbsp;</span></span></span>);

    const elementCount = typeof count === "number" && count > 1 ? count : 1;

    if (elementCount === 1) return (<span id={elementId} className={"loading-area" + elementClass} style={st}>&nbsp;</span>);

    const elements = [];
    
    for (let i = 0; i < elementCount; i++) {
        const key = elementId + "-" + i;
        const element = (<span key={key} id={key} className={"loading-area multi-" + elementCount.toString()} style={st}>&nbsp;</span>);
        elements.push(element);
    }

    return (<span className={"multi-loading-area" + elementClass}>{elements}</span>);
}

export default LoadingArea;
