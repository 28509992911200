import React, { useEffect } from 'react';
import NumberDisplay from '@jadecharles/pi-react-packages/dist/common/ui/formatting/NumberDisplay';
import DateTime from '@jadecharles/pi-react-packages/dist/common/ui/formatting/DateTime';

const InvoiceTable = (props) => {
    const { invoice, empty, labels } = props;

    useEffect(() => {
        console.log('InvoiceTable mounted');
    }, []);

    if (!Array.isArray(invoice?.items) || invoice.items.length === 0)
        return empty || null;

    const itemElements = invoice.items.map((item, index) => { 
        const amount = (item.qty * item.price) + item.taxAmount;

        return (<tr key={index}>
            <td>{item.name}</td>
            <td><NumberDisplay value={item.price} type={"currency"} /></td>
            <td><NumberDisplay value={item.qty} /></td>
            <td><NumberDisplay value={amount} type={"currency"} /></td>
        </tr>);
    });

    const summaryElements = invoice?.summary?.map((item, index) => {
        const amount = (item.qty * item.price) + item.taxAmount;
        
        return (<tr className="subtotal" key={"summary-item-" + index}>
            <td colSpan={3}>{item.name}:</td>
            <td><NumberDisplay type="currency" value={amount} /></td>
        </tr>);
    });

    const paymentSummaryElements = invoice?.payments?.map((payment, index) => {
         return (<tr className="subtotal payment" key={"payment-summary-item-" + index}>
             <td colSpan={3}>Payment on <DateTime value={payment.created} /> (AuthCode: {payment.authCode }):</td>
             <td><NumberDisplay prefix={"-"} type="currency" value={payment.total} /></td>
        </tr>);
     });

    const isPaid = !!invoice?.paidDate;
    const expectation = isPaid ? "Paid" : "Due";
    const totalDueElements = !isPaid && invoice.dueAmount > 0 ?
        (<>
            <tr className="subtotal">
                <td colSpan={3}>Total:</td>
                <td><NumberDisplay type="currency" value={invoice?.total} /></td>
            </tr>
            <tr className="total invoice-total">
                <td colSpan={3}>Due Now:</td>
                <td><NumberDisplay type="currency" value={invoice?.dueAmount} /></td>
            </tr>
        </>) :
        (<tr className="total invoice-total">
            <td colSpan={3}>Total {expectation}:</td>
            <td><NumberDisplay type="currency" value={invoice?.total} /></td>
        </tr>);

    return (<table className="x-large">
        <thead>

            <tr>
                <th>Item</th>
                <th>Price Ea.</th>
                <th>{labels?.qty || "Qty"}</th>
                <th>Amount</th>
            </tr>
        </thead>

        <tbody>
            {itemElements}
            <tr>
                <td colSpan={4} className={"no-td"}>&nbsp;</td>
            </tr>
            {summaryElements}
            {paymentSummaryElements}
            {totalDueElements}
        </tbody>
            
    </table>);
};

export default InvoiceTable;
