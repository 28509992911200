/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FinanceMasterPage from './FinanceMasterPage';
import CreditCardForm from '@jadecharles/pi-react-packages/dist/finance/forms/CreditCardForm';
import InvoiceTable from '../../components/finance/ui/InvoiceTable';
import InvoiceService from '../../components/finance/services/InvoiceService';
import FormButton from '@jadecharles/pi-react-packages/dist/common/forms/FormButton';
import AddressModel from '@jadecharles/pi-react-packages/dist/geo/models/AddressModel';
import '@jadecharles/pi-react-packages/dist/common/forms/forms.css';
import DateTime from '@jadecharles/pi-react-packages/dist/common/ui/formatting/DateTime';
import Controller from '@jadecharles/pi-react-packages/dist/common/controllers/FormController';
import CompanyModel from '../../components/companies/models/CompanyModel';
import AttachmentsPanel from '../../components/documents/ui/AttachmentsPanel';
import NotFoundScreen from '../NotFoundScreen';
import LoadingArea from '../../components/common/ui/LoadingArea';
import WebVisitorService from '@jadecharles/pi-react-packages/dist/common/services/WebVisitorService';
import InvoiceLogo from '../../components/common/ui/InvoiceLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import NumberDisplay from '@jadecharles/pi-react-packages/dist/common/ui/formatting/NumberDisplay';

const InvoicePaymentScreen = (props) => {
    const { invoiceId } = useParams();
    const [invoice, setInvoice] = useState(null);
    const [errors, setErrors] = useState({});
    const [viewState, setViewState] = useState({});
    const controller = useState(new Controller())[0];
    const isCompleted = (invoice?.status >= 10 || errors?.not_found === true);

    if (!!invoice?.id) { 
        document.title = (!!invoice?.paidDate ? "Receipt" : "Invoice") + ": " + invoice?.invoiceNumberDisplay;
    }

    const getInvoiceAsync = async (force = false, isComplete = false) => { 
        if (!force && invoice) return invoice;
        
        //await new Promise((resolve) => setTimeout(resolve, 4000));

        return await InvoiceService.instance.getInvoiceAsync(invoiceId).then((inv) => {
            if (!!inv?.id) {
                if (isComplete) inv.status = 10;
                setInvoice(inv);
                return inv;
            } else {
                setErrors({ not_found: true });
                console.warn("No dice: " + inv?.id);
                console.log(JSON.stringify(inv, null, 4));
            }

            return invoice;
        }).catch((ex) => {
            setErrors({ not_found: true });
         });
    };

    const invoiceDocumentId = "000-001";
    const receiptDocumentId = "000-002";

    const downloadPdfAsync = async (documentId, viewId = 1) => { 
        setViewState({ downloading: viewId });

        console.log("Downloading (" + viewId + ")...");
        console.log(" > Doc: " + documentId);
        console.log(" > Invoice: " + invoiceId);

        await InvoiceService.instance.downloadPdfAsync(documentId, invoiceId).catch((ex) => { 
            const message = ex?.response?.data?.message || (ex?.message || "Failed to download PDF.");
            console.log("Failed: " + message);
            setErrors({ top: message });
        });

        console.log("Good.");
        setViewState({ downloading: undefined });
    };

    const payInvoiceAsync = async () => { 
        console.log("Pay");

        if (typeof controller?.setErrors === "function")
            controller?.setErrors({});
        
        setErrors({});

        const json = controller.getData("creditcardform");

        if (!json) { 
            console.error("Failed.");
            return false;
        }

        const formElement = document.getElementById("payment-form");
        if (!!formElement) formElement.classList.add("loading");

        let paidInvoice = await InvoiceService.instance.capturePaymentAsync(invoiceId, json).catch((err) => { 
            const errorMessage = err?.response?.data?.message || (err?.message || "An unknown error occurred.");
            setErrors({ general: errorMessage });
        });

        if (!!paidInvoice?.id) {
            paidInvoice = await getInvoiceAsync(true, true);
            paidInvoice.status = 10;
        } else { 
            paidInvoice = invoice;
        }

        if (!!formElement)
            formElement.classList.remove("loading");

        return invoice;
    };

    useEffect(() => { 
        getInvoiceAsync();
        WebVisitorService.saveAsync();

        console.warn("Props.location (" + (typeof props.location) + ")");
        for (let n in props.location) { 
            console.log(n);
        }

    }, []);


    const isPaid = !!invoice?.paidDate;

    let invoiceTable = (<div>Loading Invoice...</div>);
    let expectationElement = null;
    let expectationClassName = "due";

    if (isCompleted) {
        if (errors?.not_found === true)
            return (<NotFoundScreen imageUrl={InvoiceLogo.base64PiLogo} />);

        invoiceTable = (<div>
            <h2>Thank you for your payment</h2>
            <p>
                A receipt has been sent to <strong>{invoice?.company?.email}</strong>
            </p>
        </div>);
        
    } else if (!!invoice?.id) {
        invoiceTable = (<InvoiceTable invoice={invoice} />);
        let label = "Paid on: ";

        if (isPaid) expectationClassName = "paid";
        else if (invoice?.dueDate instanceof Date) {
            const d = new Date();
            const todayKey = d.getFullYear().toString() + "-" + d.getMonth().toString() + "-" + d.getDate().toString();
            const dueDateKey = invoice.dueDate.getFullYear().toString() + "-" + invoice.dueDate.getMonth().toString() + "-" + invoice.dueDate.getDate().toString();
            
            if (todayKey === dueDateKey) {
                expectationClassName = "overdue";
                label = "Due Today: ";
            } else if (invoice.dueDate < d) {
                expectationClassName = "overdue";
                label = "Overdue: ";
            } else if (invoice.dueDate < d.addDays(5)) {
                label = "Due Soon: ";
                expectationClassName = "due-soon";
            } else { 
                label = "Due on: ";
            }
        }

        expectationElement = isPaid ?
            (<DateTime prefix={label} value={invoice.paidDate} />) :
            !!invoice?.dueDate ? (<span className="due"><DateTime prefix={label} value={invoice.dueDate} /></span>) : null;
    }

    const getAddressLine = (address) => { 
        if (!address) return "";
        const items = [];
        if (!!address.street) items.push(address.street);
        if (!!address.unit) items.push(address.unit);

        return items.join(", ").trim();
    };

    const getCityStateZip = (address, includeCountry = false) => {
        if (!address) return "";

        let items = [];
        if (!!address.city) items.push(address.city);
        if (!!address.state) items.push(address.state);

        const cityState = items.join(", ").trim();
        items = [];

        if (!!cityState) items.push(cityState);
        if (address.zip) items.push(address.zip);
        
        if (!!address.country && includeCountry) {
            const country = AddressModel.countryMap[address.country];
            items.push(country?.name || address.country);
        }

        return items.join(" ").trim();
    }
    
    const address = invoice?.company?.address || null;

    const fromCompany = CompanyModel.houseCompany();
    const fromAddress = fromCompany.address;
    const card = { billing_zip: address?.zip };  //new CreditCardModel({ billing_zip: "92124"});

    const paymentForm = (invoice?.paidDate !== null) ? null :
        (<div className="payment-form" id="payment-form">
        <div className="payment-tabs">
            <span><a onClick={() => null}>Credit Card Payment Information</a></span>
        </div>
        <CreditCardForm labels={{ exp_year: " ", exp_month: "Expires:" }} value={card} useButton={false} controller={controller} />
    </div>);

    const paymentAmount = invoice?.dueAmount || invoice?.total || 0.0;
    const paymentButtonElements = !!invoice?.paidDate ? null : (<><div className="general form-error">{errors?.general}</div>
        <div className="invoice-buttons">
            <FormButton onClick={payInvoiceAsync}>Process Payment <NumberDisplay type="currency" value={paymentAmount} /></FormButton>
        </div>
    </>);

    const attachmentsElement = invoice?.documents?.length > 0 ?
        (<AttachmentsPanel collectionId={invoice?.id} documents={invoice.documents} title="Attachments" />) : null
    
    const messageElement = !!invoice?.description?.length > 0 ?
        (<div className="invoice-message">{ invoice?.description }</div>) : null;
    
    const body = isCompleted ? (<div></div>) :
        (<>{messageElement}
            {attachmentsElement}
            {paymentForm}
            {paymentButtonElements}</>);
    
    const docTypeName = isPaid ? "Receipt" : "Invoice";
    const loadingClass = (!!invoice?.id) ? "loaded-area-content" : "loading-area-content";

    const createDownloadElement = (documentId = "") => {
        if (!invoice?.id) return null;
        if (viewState.downloading === 1) return (<LoadingArea isSpinner={true} className={"header-downloading"} isBlock={false} />);

        if (typeof documentId !== "string") documentId = "";

        if (documentId.endsWith("002")) documentId = receiptDocumentId; 
        else if (documentId.endsWith("001")) documentId = invoiceDocumentId;
        else if (documentId.length < 30) documentId = invoice?.paidDate ? receiptDocumentId : invoiceDocumentId;

        return (<a onClick={async () => downloadPdfAsync(documentId)} className="download">
            <FontAwesomeIcon icon={faDownload} /> <span>Download</span>
        </a>);
    };

    const downloadPdfElement = createDownloadElement();

    return (<FinanceMasterPage>
        <div className={"invoice-payment-body " + loadingClass}>
            <div className="is-mobile">
                <span id={"mobile-logo"}><InvoiceLogo alt="Company" /></span>
                <h1 className="flex">
                    <LoadingArea id="invoice-title" target={invoice}>{docTypeName} {invoice?.invoiceNumberDisplay}</LoadingArea>
                    {downloadPdfElement}
                </h1>
                <div className="company-line">
                    <strong>{fromCompany?.name}: </strong>
                    <span>{getAddressLine(fromAddress)}</span>
                    <span>{getCityStateZip(fromAddress)}</span>
                </div>
            </div>

            <div className="invoice-parties">
                <div className="invoicee">
                    <h1 className="is-not-mobile flex">
                        <LoadingArea id="invoice-title" target={invoice}>
                            <span>{docTypeName} {invoice?.invoiceNumberDisplay}</span>
                        </LoadingArea>
                        {downloadPdfElement}
                    </h1>
                    <div>
                        <strong>To:</strong>
                        <span className="to-value"><LoadingArea id="company-name">{invoice?.company?.name}</LoadingArea></span>
                    </div>
                    <div>
                        <strong>C/O:</strong>
                        <span className="to-value">
                            <LoadingArea id="full-name">{invoice?.company?.primaryUser?.fullName}</LoadingArea>
                        </span>
                    </div>

                    <div className="no-border no-bottom-padding">
                        <strong>Address:</strong>
                        <span className="to-value"><LoadingArea id="address-street" target={ address }>{getAddressLine(address) }</LoadingArea></span>
                    </div>
                    <div>
                        <strong></strong>
                        <span className="to-value">
                            <LoadingArea id="address-city-state-zip" target={address || null}>{getCityStateZip(address)}</LoadingArea>
                        </span>
                    </div>
                </div>
                <div className="invoicer">
                    <div className="no-border">
                        <InvoiceLogo className="invoice-company-logo" alt="Company" />
                    </div>
                    
                    <div className="no-border">
                        <strong>From:</strong>
                        <span className="to-value"><LoadingArea>{fromCompany?.name}</LoadingArea></span>
                    </div>

                    <div className="no-border no-bottom-padding">
                        <strong>Address</strong>
                        <span className="to-value"><LoadingArea>{fromAddress?.street}</LoadingArea></span>
                    </div>

                    <div>
                        <strong></strong>
                        <span className="to-value"><LoadingArea>{fromAddress?.city}, {fromAddress?.state} {fromAddress?.zip}</LoadingArea></span>
                    </div>
                </div>
            </div>

            <div className={"expectation-status " + expectationClassName}>{expectationElement}</div>
        
            <div className="invoice-items">
                <LoadingArea target={invoice?.id || null}></LoadingArea>
                <LoadingArea target={invoice?.id || null}>{invoiceTable}</LoadingArea>
                <LoadingArea target={invoice?.id || null}></LoadingArea>
            </div>

            <div className="invoice-payment-form">
                <LoadingArea target={invoice?.id || null}>{ body }</LoadingArea>
                <LoadingArea target={invoice?.id || null}></LoadingArea>
                <LoadingArea count={3} target={invoice?.id || null}></LoadingArea>
                <LoadingArea target={invoice?.id || null}></LoadingArea>
            </div>

        </div>
    </FinanceMasterPage>);
}

export default InvoicePaymentScreen;
