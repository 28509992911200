/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import './App.css';
import './AppMobile.css';
import logo from './images/invoice-logo256x232.png';
import WebVisitorService from '@jadecharles/pi-react-packages/dist/common/services/WebVisitorService';
import HttpService from './components/common/services/HttpService';

function App() {
    const saveVisitAsync = async (ipAddress = null, index = -1) => {
        if (!ipAddress) {
            console.log("No IP Address... Trying to get it now...");
            ipAddress = await HttpService.getIpAddressAsync(false);
        }
        
        await WebVisitorService.saveAsync();
    };

    useEffect(() => {
        if (!!HttpService.ipAddress) saveVisitAsync(HttpService.ipAddress, -10);
        else HttpService.queue.push((p) => saveVisitAsync(p.ip, p.index));
    }, []);

    return (
        <div className="app">
        <header className="App-header">
            <p>
                </p>
                
                <a className="App-link" href="https://penumbralabs.io" rel="noopener noreferrer">PenumbraLabs.io
                    <br /><br />
                    <img alt="Pi" src={logo} className="App-logo" />
                </a>

        </header>
        </div>
    );
}

export default App;
