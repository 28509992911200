import React, { useEffect, useState } from 'react';
import CompanyService from '../../components/companies/services/CompanyService';
import Controller from "@jadecharles/pi-react-packages/dist/common/controllers/Controller";

const FinanceMasterPage = (props) => {
    const { children, onRefresh, onCompany, className, controller } = props;
    const domain = window.location.host;
    const [company, setCompany] = useState(props.company || CompanyService.instance.companyMap[domain] || null);
    // PH2: Get session, if applicable.

    const onCompanyLoad = (c) => {
        if (typeof c?.id !== "string" || c.id.length < 30) return;

        if (typeof onCompany === "function")
            onCompany(c);
    };
    
    const getCompanyAsync = async (force = false) => { 
        if (!force && !!company?.id) {
            onCompanyLoad(company);
            return company;
        }

        console.warn("Getting company by: " + domain);

        return await CompanyService.instance.getCompanyByDomainAsync(domain).then((c) => {
            if (!!c?.id) {
                console.warn("Got company by: " + c.name);

                setCompany(c);
                onCompanyLoad(c);
                return c;
            }

            console.error("Did not get company: " + c);

            return company;
        }).catch((ex) => {
            console.error("Error getting company: " + ex);
         });
    };

    const setController = () => { 
        if (controller instanceof Controller && typeof onRefresh === "function") { 
            controller.refreshAsync = onRefresh;
        }
    }

    useEffect(() => {
        setController();
        getCompanyAsync();
        //console.warn("Finance MasterPage UseEffect");
    });

    const cn = typeof className === "string" && className.trim().length > 0 ?
        className :
        "main-body invoice-payment-screen";

    const refreshElement = typeof onRefresh === "function" ?
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        (<a onClick={onRefresh}>Refresh</a>) : null;
    
    return (<div id="main" className={cn}>
        {refreshElement}
        {children}
    </div>);
}

export default FinanceMasterPage;
