import React, { useEffect } from 'react';

const NotFoundScreen = (props) => {
    const { imageUrl } = props;

    useEffect(() => { 
        console.log("NotFoundScreen");
    }, []);

    const cn = "not-found";
    const imageElement = typeof imageUrl === "string" && imageUrl.trim().length > 0 ?
        (<img src={imageUrl} alt="404" />) : null;

    return (<div id="main" className={cn}>
        {imageElement}
        <h1>404: Not Found</h1>
    </div>);
}

export default NotFoundScreen;
