import UserModel from "../../people/models/UserModel";
import AddressModel from "@jadecharles/pi-react-packages/dist/geo/models/AddressModel";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";
import ImageModel from "../../media/models/ImageModel";
import CompanyDomainModel from "./CompanyDomainModel";

class CompanyModel { 
    static entityType = 4;
    static icon = faBriefcase;
    static houseCompanyId = "a32b21b2-1a90-4eca-afaa-6e8a08c60edd";

    static create = (json) => !!json ? new CompanyModel(json) : null;
    
    static houseCompany() {
        const json = {
            id: CompanyModel.houseCompanyId,
            name: "Penumbra Labs",
            address: {
                street: "5808 Portobelo Ct.",
                city: "San Diego",
                state: "CA",
                zip: "92124",
            },
        };

        return new CompanyModel(json);
    }

    static stages = [
        { id: -2, name: "Transitioning" },
        { id: 0, name: "New Company" },
        { id: 1, name: "Intro Call Completed" },
        { id: 2, name: "Negotiations" },
        { id: 3, name: "Pre-Qualification" },
        { id: 4, name: "Pre-Qualification Completed" },
        { id: 5, name: "Pre-Discovery" },
        { id: 6, name: "Discovery" },
        { id: 7, name: "Discovery Completed" },
        { id: 8, name: "Pre-Engagement" },
        { id: 9, name: "Engagement" },
        { id: 10, name: "Engagement Completed" },
    ];

    // None = 0,
    // Client = 1,
    // Vendor = 2,
    // IntegrationPartner = 3,
    // CompanyIdea = 4,
    static companyTypes = [
        { id: -1, name: "House" },
        { id: 0, name: "None" },
        { id: 1, name: "Client" },
        { id: 2, name: "Vendor" },
        { id: 3, name: "Integration Partner" },
        { id: 4, name: "Company Idea" },
        { id: 5, name: "Competition or Recon"}
    ];
    
    constructor(json) {
        if (!json) json = {};

        this.id = json.id;
        this.companyNumber = json.company_number || -1;
        this.name = json.name;
        this.companyUnit = json.company_unit || "";
        this.companyType = json.company_type || 0;
        this.companyTypeName = json.company_type_name || "None.";
        
        this.parentCompanyId = json.parent_company_id;
        this.ein = json.ein || "";
        this.phone = json.phone;
        this.email = json.email;
        this.stage = json.stage || 0;   // Lead=0, Prospect=1, Customer=2, Partner=3, Vendor=4, Supplier=5, Employee=6, Other=7
        this.stageName = json.stage_name || "Lead";
        this.website = json.website;
        this.websiteDisplay = this.website + "";
        this.description = json.description || "";
        this.primaryUserId = json.primary_user_id;
        this.addressId = json.address_id || null;
        this.address = new AddressModel(json.address);
        this.imageId = json.image_id;
        this.image = new ImageModel(json.image);
        this.imageUrl = json.image_url;
        this.companyKey = json.company_key;
        this.status = json.status;

        this.address = new AddressModel(json.address);
        this.primaryUser = new UserModel(json.primary_user);
        this.domains = CompanyDomainModel.fromJsonArray(json.domains);
        
        this.companyType = json.company_type;
        this.companyNumber = json.company_number;

        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;

        this.fullName = json.name || "";
        
        if (this.companyUnit.length > 0)
            this.fullName = this.name + ", " + this.companyUnit;
        
        if (this.website) {
            if (!this.website.startsWith("http"))
                this.website = "http://" + this.website;
        }

        if (this.websiteDisplay) { 
            this.websiteDisplay = this.websiteDisplay.replace("http://", "");
            if (this.websiteDisplay.startsWith("www.")) this.websiteDisplay = this.websiteDisplay.replace("www.", "");
        }
    }

    searchFor(term, descriptionLength = 5) { 
        if (!term) return true;
        term = term.toLowerCase();

        if (this.primaryUser && this.primaryUser.searchFor(term)) return true;
        
        if (this.name.toLowerCase().indexOf(term) > -1) return true;
        if ((this.email || "").toLowerCase().indexOf(term) > -1) return true;
        if ((this.website || "").toLowerCase().indexOf(term) > -1) return true;

        if (this.description && this.description.length > 2) {
            if (term.length >= descriptionLength || (!!this.description && this.description.toLowerCase().includes(term))) return true;
            if ((this.description.match(/term/g) || []).length >= 3) return true;
        }
        
        return (this.phone || "").toLowerCase().indexOf(term) > -1;
    }
    
    toJson() { 
        return { 
            name: this.name,
            ein: this.ein,
            phone: this.phone,
            email: this.email,
            stage: this.stage,
            website: this.website,
            description: this.description,
            address: this.address?.toJson(),
            company_type: this.companyType,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(x => new CompanyModel(x));
    }
    
}

export default CompanyModel;

