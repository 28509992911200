import ImageThumbModel from "./ImageThumbModel";

class ImageModel {
    static entityType = 6;
    static create = (json) => new ImageModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.userId = json.user_id || "";
        this.fileName = json.file_name || "";
        this.identifier = json.identifier || "";
        this.width = json.width || 0;
        this.height = json.height || 0;
        this.name = json.name || "";
        this.description = json.description || "";
        this.fileSize = json.file_size || 0;
        this.url = json.url || "";
        this.status = json.status || 0;
        this.id = json.id || "";
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
        this.thumbs = ImageThumbModel.fromJsonArray(json.thumbs);
        this.thumbMap = {};
        
        this.thumbs.forEach(thumb => this.thumbMap[thumb.sizeTag] = thumb);
    }
}

export default ImageModel;
