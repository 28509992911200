import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InvoicePaymentScreen from './routes/finance/InvoicePaymentScreen';
import HttpService from './components/common/services/HttpService';
import MediaScreen from './routes/media/MediaScreen';
import WebVisitorService from '@jadecharles/pi-react-packages/dist/common/services/WebVisitorService';

const root = ReactDOM.createRoot(document.getElementById('root'));

const httpOptions = {
    baseUrl: "https://dark.penumbralabs.io",
    localUrl: "https://localhost:5001",
    developmentUrl: "https://localhost:5001",
};

HttpService.init(httpOptions);
WebVisitorService.instance.httpService = HttpService.instance;

const domain = typeof window !== "undefined" ? window.location.hostname : "";
const baseRoutes = [(<Route key="app" path="/" element={<App />} />)];

if (domain.startsWith("payments.") || domain.startsWith("localhost")) {
    document.title = "Payments";
    baseRoutes.push(<Route key="payment" path="/:invoiceId" element={<InvoicePaymentScreen />} />)
}

root.render(
    <BrowserRouter>
        <Routes>
            {baseRoutes}
            <Route key="payment-full" path="/invoice/:invoiceId" element={<InvoicePaymentScreen />} />
            <Route key="media" path="/media" element={<MediaScreen />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
